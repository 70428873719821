import $ from 'jquery'

class ToggleParentMethods {
    constructor(toggleActivator, toggleSelector, toggleClassActivator) {
        this.elements = {
            toggleActivator: $(toggleActivator), 
            toggleSelector: toggleSelector, 
            toggleClassActivator: toggleClassActivator
        }

        this.events()
    }

    events() {
        this.elements.toggleActivator.on('click', this.toggleInit.bind(this))
    }

    toggleInit() {
        $(event.target).parents(this.elements.toggleSelector).toggleClass(this.elements.toggleClassActivator);
    }
}

export default ToggleParentMethods