import $ from 'jquery'

class InstagramLink {
    constructor(targetEl, wrappedEl) {
        this.elements = {
            targetEl: $(targetEl),
            wrappedEl: wrappedEl
            
        }

        this.events()
    }

    events() {
        this.elements.targetEl.wrap(this.elements.wrappedEl)
    }
}

export default InstagramLink