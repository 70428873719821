import $ from 'jquery'

class MediaCaptions {
    constructor() {
        
        $(".c-row--image-media-lockup .c-media__actions").each(function (){
            $(this).insertBefore($(this).parent());
        });
    }
}

export default MediaCaptions