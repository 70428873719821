import $ from 'jquery'

class AddHtmlAsContent {
    constructor(targetAttr) {
        
        this.elements = {
            targetAttr: $(targetAttr)
        }

        this.elements.targetAttr.each(function() {
            const innerContent = $(this).html();
            $(this).attr("data-content", innerContent);
        });
    }
}

export default AddHtmlAsContent