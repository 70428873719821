import $ from 'jquery'

class ContentPosts {
    constructor() {  
        $('.c-content-posts__filter-select').on('change', function () {
            $(this).parents('form').trigger('submit');
        })
    }   
}

export default ContentPosts

