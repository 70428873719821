import $ from 'jquery'

class AddClassMethods {
    constructor(addClassTarget, addClassSelector, addClassActivator) {
        this.elements = {
            addClassTarget: $(addClassTarget), 
            addClassSelector: $(addClassSelector), 
            addClassActivator: addClassActivator
        }

        this.events()
    }

    events() {
        this.elements.addClassTarget.on('click', this.removeInit.bind(this))
    }

    removeInit() {
        this.elements.addClassSelector.addClass(this.elements.addClassActivator);
    }
}

export default AddClassMethods