import $ from 'jquery'

class MediaImageChange {
    constructor() {

        $(".no-touchevents .c-row--media-image-hover-change .c-media__content").click(function(){
            $(".c-row--media-image-hover-change .c-media__content").removeClass("is-active");
            $(this).delay(300).addClass("is-active");
        });
    }
}

export default MediaImageChange