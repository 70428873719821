import { filter, join, map } from 'lodash'

class LdeBookingForm {
    constructor(el) {
        this.elements = {
            form: el,
            holidayType: el.find('.js-lde-booking-form-holiday-type'),
            pitchSizeFieldGroup: el.find('.js-lde-booking-form-pitch-size'),
            pitchSizeDropdown: el.find('.js-lde-booking-form-pitch-size select'),
            dogFriendlyFieldGroup: el.find('.js-lde-booking-form-dog-friendly'),
            dogFriendlyCheckbox: el.find('.js-lde-booking-form-dog-friendly input'),
            stayFieldGroup: el.find('.js-lde-booking-form-stay-fields'),
            checkInDate: el.find('.js-booking-form-check-in'),
            checkOutDate: el.find('.js-booking-form-check-out'),
            hiddenRoomFilter: el.find('.js-lde-booking-form-room-filter'),
            submitButton: el.find('.js-lde-booking-form-button')
        }

        this.options = el.data('module-options')

        this.state = {
            holidayType: {},
            validRooms: []
        }

        this.elements.holidayType.on('change', this.setHolidayType.bind(this))
        this.elements.pitchSizeDropdown.on('change', this.setHolidayType.bind(this))
        this.elements.dogFriendlyCheckbox.on('change', this.setHolidayType.bind(this))

        this.elements.form.on('submit', this.submitForm.bind(this))

        setTimeout(() => {
            this.setHolidayType()
        }, 100)
    }

    setHolidayType() {
        let holidayType = this.elements.holidayType.val()

        if (holidayType) {
            this.updateState(holidayType)
            this.setRoomFilterValue()
            this.setDisabledDates()
            this.enableStayForm()
        } else {
            this.resetState()
            this.disableStayForm()
        }

        this.resetDatepickers()
        this.togglePitchSizeField()
        this.toggleDogFriendlyField()
    }

    submitForm() {
        this.elements.form.find('[name="checkInDate"]').attr('name', 'arrival')
        this.elements.form.find('[name="checkOutDate"]').attr('name', 'departure')
    }

    updateState(holidayType) {
        this.state.holidayType = this.options.holidayTypes[holidayType]
        this.state.validRooms = this.getValidRooms()
    }

    resetState() {
        this.state = {
            holidayType: {
                hasPitchSizeFilter: false,
                hasDogFriendlyFilter: false
            }
        }
    }

    enableStayForm() {
        this.elements.stayFieldGroup.show()
        this.elements.submitButton.prop('disabled', false)
    }

    disableStayForm() {
        this.elements.stayFieldGroup.hide()
        this.elements.submitButton.prop('disabled', true)
    }

    getValidRooms() {
        if (this.isDogFriendlySearch()) {
            return this.getDogFriendlyRooms()
        }

        if (this.isPitchSizeSearch()) {
            return this.getValidRoomsForPitchSize()
        }

        return map(this.state.holidayType.rooms, 'id')
    }

    isDogFriendlySearch() {
        if (!this.state.holidayType.hasDogFriendlyFilter) {
            return false
        }

        if (!this.elements.dogFriendlyCheckbox.prop('checked')) {
            return false
        }

        return true
    }

    isPitchSizeSearch() {
        return this.state.holidayType.hasPitchSizeFilter
    }

    getDogFriendlyRooms() {
        return map(filter(this.state.holidayType.rooms, 'dogFriendly'), 'id')
    }

    getValidRoomsForPitchSize() {
        let selectedPitchSize = parseInt(this.elements.pitchSizeDropdown.val())

        if (isNaN(selectedPitchSize)) {
            return map(this.state.holidayType.rooms, 'id')
        }

        return map(filter(this.state.holidayType.rooms, {
            pitchSize: selectedPitchSize
        }), 'id')
    }

    togglePitchSizeField() {
        if (this.state.holidayType.hasPitchSizeFilter) {
            this.elements.pitchSizeFieldGroup.show()
        } else {
            this.elements.pitchSizeFieldGroup.hide()
        }
    }

    toggleDogFriendlyField() {
        if (this.state.holidayType.hasDogFriendlyFilter) {
            this.elements.dogFriendlyFieldGroup.show()
        } else {
            this.elements.dogFriendlyFieldGroup.hide()
        }
    }

    resetDatepickers() {
        this.elements.checkInDate.pickadate('picker').clear()
        this.elements.checkOutDate.pickadate('picker').clear()
    }

    setDisabledDates() {
        this.elements.checkInDate.pickadate('picker')
            .set('enable', true)
            .set('disable', this.state.holidayType.restrictedDays)

        this.elements.checkOutDate.pickadate('picker')
            .set('enable', true)
            .set('disable', this.state.holidayType.restrictedDays)
    }

    setRoomFilterValue() {
        this.elements.hiddenRoomFilter.val(join(this.state.validRooms, ';'))
    }
}

export default LdeBookingForm
