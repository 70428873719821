import $ from 'jquery'

class InsertSpanAfter {
    constructor(htmlEl, targetEl) {

        this.elements = {
            htmlEl: $(htmlEl),
            targetEl: targetEl
        }

        this.events()
    }

    events() {
        this.elements.htmlEl.insertAfter(this.elements.targetEl)
    }
}

export default InsertSpanAfter