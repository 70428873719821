import $ from 'jquery'

class Clamp {
    constructor(clampedNode, clampValue) {
        this.clampNodes = [
            clampedNode
        ];

        this.clampValue = clampValue
        
        this.events()
        
    }

    events() {
        $.each(this.clampNodes, (index, element) => $(element).each((index, element) => $clamp(element, {clamp: this.clampValue})));
    }
}

export default Clamp