import $ from 'jquery'

class RemoveClassMethods {
    constructor(removeClassTarget, removeClassSelector, removeClassActivator) {
        this.elements = {
            removeClassTarget: $(removeClassTarget), 
            removeClassSelector: $(removeClassSelector), 
            removeClassActivator: removeClassActivator
        }

        this.events()
    }

    events() {
        this.elements.removeClassTarget.on('click', this.removeInit.bind(this))
    }

    removeInit() {
        this.elements.removeClassSelector.removeClass(this.elements.removeClassActivator);
    }
}

export default RemoveClassMethods