import $ from 'jquery'

class SiblingHover {
    constructor(targetEl) {

        this.elements = {
            targetEl: $(targetEl)
        }

        this.events()
    }

    events() {
        this.elements.targetEl.hover(
        function(){ 
            $(this).siblings().addClass("sibling-is-hovering");
        }, 
        function(){ 
            $(this).siblings().removeClass("sibling-is-hovering");
        })
    }
}

export default SiblingHover