import $ from 'jquery'

class InsertElementInto {
    constructor(targetEl, destinationEl) {

        this.elements = {
            targetEl: $(targetEl),
            destinationEl: destinationEl
        }

        this.events()
    }

    events() {
        this.elements.targetEl.insertBefore(this.elements.destinationEl)
    }
}

export default InsertElementInto